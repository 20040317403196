

import React from 'react'
import { useState, useEffect } from 'react'
import Title from "../title/Title"
import Card from './servicesCopm/Card'
import serve1 from "../../assets/imgs/icon/brain.svg"
import serve2 from "../../assets/imgs/icon/vector.svg"
import serve3 from "../../assets/imgs/icon/brain2.svg"

import LeftStyle from '../styleElment/LeftStyle'

function Services({ limit }) {

  let titleObject = {
    "nameTitle": "Our services",
    "descriptionTitle": "By continuously adhering to best practices and established methodologies, we are committed to offering comprehensive support that empowers everyone to achieve optimal mental health."
  }

  let servicesArray = [
   {
      id: 1,
      img: serve1,
      titleServe: "Psychological assessment ",
      descriptionServe: "Our comprehensive evaluation process includes specialized assessments for Motor Vehicle Accidents (MVA), refugees, and private clients. ",
      "classColor": "icon-color1"
    },
    {
      id: 2,
      img: serve2,
      titleServe: "Individual therapy",
      descriptionServe: "Personalized psychotherapy sessions focused on addressing personal challenges, mental health concerns, and emotional well-being",
      "classColor": "icon-color2"
    },
    {
      id: 3,
      img: serve3,
      titleServe: "Family therapy",
      descriptionServe: "Therapeutic support aimed at improving communication, resolving conflicts, and fostering stronger relationships within families.",
      "classColor": "icon-color3"
      
    },
    {
      id: 4,
      img: serve1,
      titleServe: "Workshops and Training Programs",
      descriptionServe: "Training sessions designed for professionals seeking to enhance their skills in mental health practices, offering specialized training to deepen expertise and advance careers in the field.",
      "classColor": "icon-color1"
    },
    {
      id: 5,
      img: serve2,
      titleServe: "Clinical supervision",
      descriptionServe: "We provide clinical supervision for mental health professionals, offering expert guidance and support to ensure high standards of practice, continuous professional development, and effective client care.",
      "classColor": "icon-color2"
    },
    {
      id: 6,
      img: serve3,
      titleServe: "Digital gateway to mental health training",
      descriptionServe: "A comprehensive online training platform offering both specialized and general mental health education, serving as a trusted resource for professionals and individuals alike.",
      "classColor": "icon-color3"
    }
  ]


  let [limitArray, setLimitArray] = useState([])



  useEffect(() => {
    if (limit) {
      setLimitArray(servicesArray.slice(0, 6))
    } else {
      setLimitArray(servicesArray)

    }
  }, [])

  return (
    <section  className='our-services' >
      <LeftStyle />
      <div className="container">
        <Title titleObject={titleObject} />
        <div className="services-grid grid-style">
          {limitArray.map((data) => {
            return (
              <Card data={data} key={data.id} />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Services




