import React, { useState } from 'react'
import heroImg2 from "../../assets/imgs/elment/heroImg3.png"
import patient from "../../assets/imgs/elment/patient.png"

import playImg from "../../assets/imgs/icon/play.svg"
import xmark from "../../assets/imgs/icon/xmark.svg"

import company1 from "../../assets/imgs/company/1.svg"
import company2 from "../../assets/imgs/company/2.svg"
import company3 from "../../assets/imgs/company/3.svg"
import company4 from "../../assets/imgs/company/4.svg"
import company5 from "../../assets/imgs/company/5.svg"
import company6 from "../../assets/imgs/company/6.svg"
import company7 from "../../assets/imgs/company/7.svg"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';







import { Link } from 'react-router-dom'
import { useRef } from 'react'


function HeroSection() {
    const iframeElement = useRef();


    let [toggle, setToggle] = useState(false)


    let srcIframe = "https://www.youtube.com/embed/rkZl2gsLUp4?si=iobGLkuFfQ9z6Dd7"


    let toggleIframe = (e) => {
        setToggle(!toggle)

    }
    let toggleIframeX = (e) => {
        setToggle(!toggle)
        iframeElement.current.src = srcIframe
    }



    return (
        <main className="main-sec">

            <div className={toggle ? "active-video-pop active" : "active-video-pop"}>
                <span className="closex closex-iframe" onClick={(e) => toggleIframeX(e)}  >
                    <img className="iconDefault" src={xmark} alt="icon" />
                </span>

                <div className="frame-pop-video">
                    <iframe
                        preload="none"
                        ref={iframeElement} title="Demo video" className="absolute h-full w-full rounded-b-md" id="iframe"
                        src={srcIframe}
                        frameBorder="0" allow="autoplay; modestbranding; encrypted-media"></iframe>
                </div>
            </div>
            <div className="container">
                <div className="main-content">
                    <div className="main-text">
                        <div className="title">
                            <div>
                                <h1 data-aos="fade-up" data-aos-duration="1000">Essence of Being</h1>
                                <h2 data-aos="fade-up" data-aos-duration="1200">
                                    
                                     Embrace Your Being
                                </h2>
                            </div>
                            <p data-aos="fade-up" data-aos-duration="1300">
                                Prioritize Your Mental Well-Being with Essence of Being
                            </p>
                        </div>
                        <div className="get-started-div flex-div-align">
                            <Link to="/book-now" className="button" href="#" data-aos="fade-up" data-aos-duration="1400"> Get Started</Link>
                            <span className="play-video-icon" data-aos="fade-up" data-aos-duration="1500" onClick={(e) => toggleIframe(e)} >
                                <img src={playImg} alt="play" />
                            </span>
                        </div>

                    </div>

                    <div className="website-main-sec">
                        <div className="phone-div-flow-imgs">

                            <div className="pop-img-div pop3">
                                <img src={patient} alt="anime img" data-aos="fade-up" data-aos-duration="1250" />
                            </div>

                            <img alt="phone app" className="hero-img" src={heroImg2}
                                data-aos="fade-up" data-aos-duration="1000"
                            />
                        </div>
                    </div>
                </div>

                 <div className="company-img-partner" data-aos="fade-up" data-aos-duration="1000">
                    <div name-company="IOM" className='company-div-img'> <img src={company1} alt="company-img" /></div>
                    <div name-company="USSOM " className='company-div-img'> <img src={company2} alt="company-img" /></div>
                    <div name-company="MSF " className='company-div-img'> <img src={company3} alt="company-img" /></div>
                    <div name-company="MIDMAR " className='company-div-img'> <img src={company4} alt="company-img" /></div>
                    <div name-company="Heartland Alliance " className='company-div-img'> <img src={company5} alt="company-img" /></div>
                    <div name-company="HIHFAD " className='company-div-img'> <img src={company6} alt="company-img" /></div>
                    <div name-company="MDM" className='company-div-img'> <img src={company7} alt="company-img" /></div>
                </div> 









<div className="support-palestine" data-aos="fade-up" data-aos-duration="1000" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#007aff', border: '1px solid #ccc', margin: '20px 0', marginTop: '10px' }}>
    <h2 className="support-title" style={{ fontSize: '24px', marginBottom: '15px', color: '#fff' }}>We Support Humanitarian Work Worldwide</h2>
    <div className="flag-container" style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
        <img src="https://essenceob.com/static/media/logo.0d3fe80d948bbfd63e2b.png" style={{ width: '150px', height: 'auto', margin: '0 5px' }} />
    </div>
    <div className="support-message">
        <p style={{ fontSize: '16px', color: '#fff' }}>At Essence Of Being, we are committed to supporting humanitarian work around the world</p>
        <p style={{ fontSize: '16px', color: '#fff' }}>We have introduced many initiatives in war zones.</p>
        <a href="https://www.facebook.com/Bilal.Budair" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <button style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: '#fff', color: '#007aff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                See Our Aid Efforts
            </button>
        </a>
    </div>
</div>




          
{/* 
                <div className="company-img-container" data-aos="fade-up" data-aos-duration="1400">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        data-aos="fade-up" data-aos-duration="1100"
                    >
                        <SwiperSlide>
                            <div name-company="IOM" className='company-div-img'> <img src={company1} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="USSOM " className='company-div-img'> <img src={company2} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="MSF " className='company-div-img'> <img src={company3} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="MIDMAR " className='company-div-img'> <img src={company4} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="Heartland Alliance " className='company-div-img'> <img src={company5} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="HIHFAD " className='company-div-img'> <img src={company6} alt="company-img" /></div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div name-company="MDM" className='company-div-img'> <img src={company7} alt="company-img" /></div>
                        </SwiperSlide>
                    </Swiper>

                </div> */}
            </div>
        </main>
    )
}

export default HeroSection