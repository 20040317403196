import React from 'react'
import Title from '../title/Title'
import Card from './teamCopm/Card'

import photo from "../../assets/imgs/team/photo.jpg"
import LeftStyle from '../styleElment/LeftStyle'



function Team() {
    let titleObject = {
        "nameTitle": "Our team",
        "descriptionTitle": "Our team consists of skilled mental health professionals committed to supporting your well-being and growth."
    }

    let servicesArray = [
       {
            id: 1,
            img: photo,
            titleServe: "Bilal Budair, MA, RP",
            descriptionServe: "Founder and Registered Psychotherapist.",
            "classColor": "icon-color1"
        },
        {
            id: 2,
            img: photo,
            titleServe: "Randa Haifawi, MA, RP",
            descriptionServe: "Registered Psychotherapist.",
            "classColor": "icon-color2"
        },
        {
            id: 3,
            img: photo,
            titleServe: "Elissar Al-Ayoubi",
            descriptionServe: "Clinic manager.",
            "classColor": "icon-color3"
        },
        {
            id: 4,
            img: photo,
            titleServe: "Rami Safi",
            descriptionServe: "Registered Psychotherapist (Qualifying).",
            "classColor": "icon-color1"
        },
        {
            id: 5,
            img: photo,
            titleServe: "Maram Asad",
            descriptionServe: "Registered Psychotherapist (Qualifying).",
            "classColor": "icon-color1"
        },
    ]


    return (
        <section>
            <LeftStyle />
            <div className="container">
                <Title titleObject={titleObject} />
                <div className="team-grid grid-style" >
                    {servicesArray.map((data) => {
                        return (
                            <Card data={data} key={data.id} />
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Team