import React from 'react';

function CopmServices1() {
  return (
    <div className='container services-page-container'>
      <div className="grid-content">
        <article className="blog-page">
          <div className="h1-blog">
            <h1>Workshops and Training Programs</h1>
          </div>
          <div className="blog-details">
            <p className="margin-text-blog">
              Training sessions designed for professionals seeking to enhance their skills in mental health practices, offering specialized training to deepen expertise and advance careers in the field.
            </p>
            <ul className="ul-list-line">
              <li>
                <h2>Comprehensive Training for Mental Health Professionals</h2>
                <p>
                  Our training programs are designed to deepen the skills and knowledge of mental health professionals working in high-need areas, offering evidence-based training on various therapeutic approaches like CBT, ACT, and trauma recovery therapies.
                </p>
              </li>
              <li>
                <h2>International Training for High-Need Areas</h2>
                <p>
                  We conduct international training for NGOs and mental health professionals in conflict zones and high-need areas, focusing on trauma, resilience, and mental health challenges.
                </p>
              </li>
              <li>
                <h2>Workshops for Personal Development and Wellness</h2>
                <p>
                  Our workshops for personal growth, mental wellness, and coping strategies help individuals and groups improve emotional intelligence and overall well-being.
                </p>
              </li>
              <li>
                <h2>Customized Programs for Organizations</h2>
                <p>
                  We collaborate with organizations, schools, and community groups to deliver customized workshops on topics such as emotional intelligence, conflict resolution, and team dynamics.
                </p>
              </li>
              <li>
                <h2>Interactive and Engaging Learning Environment</h2>
                <p>
                  Our workshops are highly interactive, utilizing role-plays, case studies, and real-life scenarios to ensure participants leave with actionable insights.
                </p>
              </li>
              <li>
                <h2>Culturally Sensitive and Multilingual Training</h2>
                <p>
                  We offer culturally sensitive training to ensure our programs resonate with diverse audiences and respect cultural nuances.
                </p>
              </li>
              <li>
                <h2>Professional Development with Certification</h2>
                <p>
                  Our training programs offer certification upon completion, supporting the professional growth and credentials of participants.
                </p>
              </li>
              <li>
                <h2>Focus on Trauma-Informed and Displacement Support</h2>
                <p>
                  We provide specialized training for professionals working with trauma survivors and displaced individuals, covering techniques in trauma-informed care and resilience building.
                </p>
              </li>
              <li>
                <h2>Flexible In-Person and Virtual Formats</h2>
                <p>
                  Our programs are available both in-person and online, ensuring flexibility for participants’ schedules and locations.
                </p>
              </li>
              <li>
                <h2>Empowering Growth and Impact</h2>
                <p>
                  Our workshops and training programs aim to empower mental health professionals and individuals, making a lasting impact in the field of mental health.
                </p>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  );
}

export default CopmServices1;
