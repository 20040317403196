import React from 'react'
import Title from '../../components/title/Title'
import Card from '../../components/review/reviewComp/Card'

function ReviewPage() {


     let reviewObj = [
        {
            "id": 1,
            "title": "Makki Al-Khowaiter",
            "description": "I thank Dr. Bilal and all the staff at this center. The service was very wonderful."
           },
        {
            "id": 2,
            "title": "Abdallah Yousef",
            "description": "He is a wonderful doctor who genuinely helps patients.A beautiful place that gives positive energy to patients."
      },
        {
            "id": 3,
            "title": "Marwa Al-Khattab",
            "description": "It was a very special and beneficial experience through which I gained qualitative skills in the field of cognitive behavioral therapy."
        },

        {
            "id": 4,
            "title": "ado time",
            "description": "I've had a remarkable experience at this clinicThe clinical psychologist and staff demonstrate exceptional professionalism"
        },

    ]

    let titleObject = {
        "nameTitle": "Review",
        "descriptionTitle": "We show you the best reviews we have received from visitors to our partners"
    }
    return (
        <div>
            <div className='container-page' >
                <div className='container' >
                    <Title titleObject={titleObject} />
                    <div className='grid-review grid-style ' >
                        {
                            reviewObj.map((data) => {
                                return (
                                    <Card data={data} key={data.title} />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewPage