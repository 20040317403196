

import React from 'react'
import BookNowComp from '../../components/book-now/BookNowComp'


function BookNowPage() {
    return (
        <div className='container-page' >
            <BookNowComp />
        </div>

    )

}

export default BookNowPage