import React from 'react'
import Contact from '../../components/contact/Contact'
function ContactPage() {
    return (
        <div className='container-page'>
            <Contact />
        </div>
    )
}

export default ContactPage