import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from "swiper";
import 'swiper/css';


import Card from './reviewComp/Card';

import Title from '../title/Title';
import RightStyle from '../styleElment/RightStyle';



function Review() {
    let titleObject = {
        "nameTitle": "Review",
        "descriptionTitle": "We show you the best reviews we have received from visitors to our partners"
    }



    let reviewObj = [
        {
            "id": 1,
            "title": "Makki Al-Khowaiter",
            "description": "I thank Dr. Bilal and all the staff at this center. The service was very wonderful."
           },
        {
            "id": 2,
            "title": "Abdallah Yousef",
            "description": "He is a wonderful doctor who genuinely helps patients.A beautiful place that gives positive energy to patients."
      },
        {
            "id": 3,
            "title": "Marwa Al-Khattab",
            "description": "It was a very special and beneficial experience through which I gained qualitative skills in the field of cognitive behavioral therapy."
        },

        {
            "id": 4,
            "title": "ado time",
            "description": "I've had a remarkable experience at this clinicThe clinical psychologist and staff demonstrate exceptional professionalism"
        },

    ]


    return (
        <section>

            <RightStyle />
            <div className="container" data-aos="fade-up" data-aos-duration="1200">
                <Title titleObject={titleObject} />

                <Swiper
                    spaceBetween={10}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                        20: {
                            slidesPerView: 1,
                        },
                        576: {
                            slidesPerView: 2,
                        },
                        1000: {
                            slidesPerView: 3,
                        },
                        1500: {
                            slidesPerView: 4,
                        },
                    }}

                >
                    {
                        reviewObj.map((data) => {
                            return (
                                <SwiperSlide key={data.id} >
                                    <Card data={data} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

            </div>
        </section >
    )
}

export default Review