import React from 'react'
import Services from '../../components/services/Services'
function ServicesPage() {
    return (
        <div className='container-page' >
            <Services />
        </div>
    )
}

export default ServicesPage