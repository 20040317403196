import React from 'react'
import Blog from '../../components/blog/Blog'



function BlogPage() {
    return (
        <div className='container-page' >
            <Blog />
        </div>
    )
}

export default BlogPage