import React from 'react'
import Project from '../../components/project/Project'



function ProjectPage() {
    return (
        <div className='container-page' >
            <Project />
        </div>
    )
}

export default ProjectPage