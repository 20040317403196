import React from 'react';

function CopmServices1() {
  return (
    <div className='container services-page-container'>
      <div className="grid-content">
        <article className="blog-page">
          <div className="h1-blog">
            <h1>Individual Therapy</h1>
          </div>
          <div className="blog-details">
            <p className="margin-text-blog">
              Personalized one-on-one therapy sessions designed to address each client's unique needs, goals, and life circumstances. We offer both in-person and virtual sessions to provide flexibility and convenience, ensuring clients receive support in their preferred setting.
            </p>
            <ul className="ul-list-line">
              <li>
                <h2>Personalized One-on-One Support</h2>
                <p>
                  Our individual therapy sessions address each client’s unique needs, goals, and life circumstances, offering both in-person and virtual sessions for flexibility and comfort.
                </p>
              </li>
              <li>
                <h2>Healing from Trauma and Abuse</h2>
                <p>
                  We provide a safe, compassionate, and confidential environment to support individuals recovering from trauma, abuse, and domestic violence, helping them build resilience and restore a sense of safety.
                </p>
              </li>
              <li>
                <h2>Managing Anxiety, Depression, and Mood Disorders</h2>
                <p>
                  Our sessions offer effective tools for those facing anxiety, depression, and mood disorders, focusing on coping skills and fostering a positive outlook on life.
                </p>
              </li>
              <li>
                <h2>Support for Life Transitions and Adjustments</h2>
                <p>
                  We guide clients through life transitions such as relocation, career changes, and relationship adjustments, helping them manage stress and find stability.
                </p>
              </li>
              <li>
                <h2>Addressing Panic and Adjustment Disorders</h2>
                <p>
                  Our therapists offer support for panic and adjustment disorders, aiding clients in managing symptoms, reducing panic attacks, and adapting to life's challenges.
                </p>
              </li>
              <li>
                <h2>Therapy for Displaced Individuals and Cultural Adjustments</h2>
                <p>
                  We provide culturally sensitive support for individuals experiencing displacement or resettlement, addressing unique stressors and emotional impacts.
                </p>
              </li>
              <li>
                <h2>Enhancing Relationships and Personal Growth</h2>
                <p>
                  Our therapy assists clients with relationship challenges and personal development goals, fostering self-awareness and healthier relationships.
                </p>
              </li>
              <li>
                <h2>Compassionate and Evidence-Based Approach</h2>
                <p>
                  Our licensed psychotherapists use scientifically backed methods within a caring and respectful environment, ensuring client comfort and trust.
                </p>
              </li>
              <li>
                <h2>Culturally Sensitive and Multilingual Services</h2>
                <p>
                  We offer therapy by professionals who speak Arabic and are skilled in Hungarian and Romanian contexts, respecting clients' cultural values.
                </p>
              </li>
              <li>
                <h2>Safe and Confidential Environment</h2>
                <p>
                  Our sessions are conducted with the utmost confidentiality and empathy, providing a secure space for clients to explore thoughts and emotions.
                </p>
              </li>
              <li>
                <h2>Empowering Lasting Change</h2>
                <p>
                  Individual therapy at Essence of Being equips clients with tools for long-term growth and resilience, extending beyond the therapy room.
                </p>
              </li>
            </ul>
          </div>
        </article>
      </div>
    </div>
  );
}

export default CopmServices1;
